                                                    <template>
  <div>
    <h1>so what do i listen to?</h1>
    <h3>podcasts</h3>
    <p>
      I have a love-hate relationship with podcasts. On one hand, they offer
      extremely diverse and unique perspectives on the world, presented as
      long-form content(which is my favorite). While I've made a conscious
      effort to surround myself with long-form content in 2023, I often have a
      hard time listening to podcasts. Not because they are difficult to listen
      to - I could listen to podcasts all day - but because they often pleasure
      ad-hoc conversations that aren't always thought out very well.
      <br />
      <br />
      I think there is a time and place for podcasts, but I would much rather
      read a piece of literature, which is often more thought out in
      excruciating detail. Over the past few years, I am more and more picky
      about which podcasts warrant my time and front-of-mind, and will typically
      listen to these to decompress or to expand my worldview. Here are some of
      the podcasts that I've listened to over the past few years:
      <br />
      <br />
      <b>Here are my favorites: </b>-to preface, these are mostly
      business-related in some manner or another, but the principals can be
      applied to many different fields imo - especially ambition and critical
      thinking about the world. <b><i>And no, im not some annoying SEO blogger, these are direct-links</i></b>
    </p>
    <ul>
      <li>
        <b
          ><a
            href="https://open.spotify.com/show/7txiovdzPARhjm18NwMUYj?si=d28c2f2067cf4cd2"
            >Founders Podcast</a
          ></b
        >
        - author reads a biography of a famous individual in history, then talks
        about how they lived their life, and often practical applications you
        can carry over to your own life.
        <br />
        -my main takeaway from this podcast, is to be relentlessly ambitious,
        and that the self-made man is achievable in almost all facets, even
        today.
        <br />
        I liked the episode on
        <a
          href="https://open.spotify.com/episode/0Hlk545Q3CK5Xej1dExnWO?si=beb5eb9ea1a94e4d"
          >Tiger Woods</a
        >
      </li>
      <li>
        <b
          ><a
            href="https://open.spotify.com/show/7qZAVw03FuurfYnWIWwkHY?si=bd65bf0950bf4e1d"
            >Naval Podcast Series</a
          ></b
        >
        - some techbro guy with some incredible insights into the world.
        <br />
        -listen to:
        <a
          href="https://open.spotify.com/episode/1DW2fkyEkgZaEP40Mj6H9m?si=9f5fc61c94d6418b"
          >"How to Get Rich: Every Episode"</a
        >
        - its 3.5hrs, but worth every second. | only listen to it though, if you
        <i>aren't</i> a boring materialist, and instead want to earn for
        external reasons...
      </li>
      <li>
        <b
          ><a
            href="https://open.spotify.com/show/2WzJwXWBDnn4iZ7odKwDib?si=55e6c8e0efcb4acd"
            >80,000 Hours podcast</a
          ></b
        >
        - utilitarian podcast. I haven't listened to many episodes, but
        regardless if you agree with hardset utilitarianism, there are a lot of
        general principles you can apply to your everyday life. There is an
        episode on SBF, pre-chaos blowup era, but actually led me to respect him
        quite a bit. However, what someone says in theory is not always what
        happens practically!
        <br />
        -heres the
        <a
          href="https://open.spotify.com/episode/18UPmoLsr9j4sdFai8y6pS?si=a0013af0b3794740"
          >SBF episode</a
        >
        <br />
        -heres
        <a
          href="https://open.spotify.com/episode/3bkfOQnGqH4UNsSEQ50rN3?si=58b34ee285734a3f"
          >another one</a
        >
        that I found interesting, about contemporary utilitarianism(dont agree
        with everything, but a lot of unique insights)
      </li>
      <li>
        <b><a href="https://open.spotify.com/show/4XPl3uEEL9hvqMkoZrzbx5?si=1d6b7b6599be4c0d">Darknet Diaries</a></b> - cybersecurity podcast, probably pretty
        mainstream, but it details some quasi-interesting stories about the
        "dark net". Aside from this normie term, i've realized holistically from
        learning about cyberspace how unsecure everything in our world really
        is... like you could easily cause a lot of chaos if you combine
        intellect with criminal intent.
        <br />
        -
        <a
          href="https://open.spotify.com/episode/5Snektk5Z2nUzM7DgoEBSx?si=ed53b64096624beb"
          >this</a
        >
        episode is really fascinating, detailing stories of individuals who
        sim-swapped Coinbase users for millions of dollars by stealing T-Mobile
        employees tablets!
      </li>
      <li>
        <b>
          <a
            href="https://open.spotify.com/show/2IqXAVFR4e0Bmyjsdc8QzF?si=4d511061210e4ca9"
            >The All In Podcast</a
          ></b
        >
        - (geopolitics, macroeconomics, and often a bunch of pointless
        conversations... its hit or miss, but there are often a lot of unique
        insights here.)
      </li>
      <li>
        <b
          ><a
            href="https://open.spotify.com/show/7Fj0XEuUQLUqoMZQdsLXqp?si=b7fdc6772abb4dfa"
            >Acquired</a
          ></b
        >
        - details a lot of successful companies/organizations, how they were
        founded, and how they got to where they are today
        <br />
        I liked the one on
        <a
          href="https://open.spotify.com/episode/0LQwuSaAzLkwATk9SjDMRC?si=27d9117268cc4b19"
          >Walmart</a
        >
        and the one on
        <a
          href="https://open.spotify.com/episode/0a9yxPff6yYlhTc9TpPO2N?si=e9b57025d40f4838"
          >Ethereum</a
        >
      </li>
      <li>
        <b
          ><a
            href="https://open.spotify.com/show/6q7Gn5lP8TTtBPuV1NJSGs?si=ce7631af1f1d414b"
            >Econ 102 with Noah Smith</a
          ></b
        >
        - some decent thoughts on here. Mainly Noah Smith is an incredibly smart
        individual with thoughts on the US/Geopolitics. The co-host is kind of
        unintelligent and your standard "silicon valley businessman" with no
        technical knowledge or new thoughts, but its worth it to listen to
        Noah's thoughts.
        <br />
        -I liked
        <a
          href="https://open.spotify.com/episode/2AaG487XKjwnQLjMLMSZLt?si=67868e16ab23454e"
          >this</a
        >
        episode on the importance of higher education(even though I dropped out,
        he has a LOT of valid points)
        <br />
        -and
        <a
          href="https://open.spotify.com/episode/5GtFZ6ADPlVGKWYyKRrE1T?si=441e88ab332a4444"
          >this</a
        >
        episode on techno optimism/innovation
      </li>
    </ul>
    <h3>audiobooks</h3>
    <p>
      Listening to audiobooks is one of my favorite pastimes, and yields a high
      ROI. I think you need to be incredibly cautious about what you decide to
      read though. When im reading something super complex that requires
      creative thinking or reflection, I don't listen to an audiobook. I was
      reading a lot of different philosophy books, and kept having to rewind it
      over, and over, and over. The philosopher may drop some banger points, but
      the speaker will keep trucking along. I found that it hindered what I was
      getting out of the books, and realized that there <i>is</i> a time and
      place for physical books(ebooks work just fine as well).
      <br />
      <br />
      Yes you can read the book faster if you listen to it, but at what cost?
      Are you actually getting true value out of your reading? Just some food
      for thought.
    </p>
    <h4>how I listen to audiobooks for free:</h4>
    <p>
      Well nothing is technically free, but you can take advantage of tax-funded
      programs, such as the Library! In Ohio(and many other US States), all you
      need is a library card, and you can access a collection of tens of
      thousands of audiobooks for free! All you need to do is download an app
      called
      <a href="https://www.overdrive.com/apps/libby">Libby(aka Overdrive)</a>.
      Also, many libraries offer subscriptions to
      <a href="https://www.hoopladigital.com/">Hoopla</a>, which carrys much of
      the same stuff. I often go here if the wait is too long on a book I want
      to read. Hoopla has a lot of books in the public domain that people have
      done voiceovers on. Typically I go to Hoopla to read philosophy or older
      books. They also carry a bunch of "entertainment", but don't waste your
      time with most of it...
      <br />
      <br />
      If all else fails, you can buy the book on Audible(which is annoyingly
      capitalist, but definitely high quality), or if you wish, you can just
      pirate them from elsewhere.
    </p>

    <h3>music:</h3>
    <p>
      For music well... it depends. Typically when I am trying to focus on
      something, I work best with "instrumental music" - meaning music without
      words. I use Spotify for the time being, mainly because of it's
      algorithms, playlists, and discovery features
      <br />
      <br />
      As per what type of instrumental music, I really enjoy some classical
      music. One of my favorite artists is <a href="https://en.wikipedia.org/wiki/Antonio_Vivaldi">Antonio Vivaldi</a>, and his Four Seasons
      Concertos. I will also listen to some lofi-hip-hop, or generic
      instrumental music. The main purpose is to channel my thinking towards
      what I am working on, and ignore everything else. I often fall victim to
      listening to music with lyrics, and it takes up a decent size of my
      unconscious mental processing capacity, thus hindering my output. I try to
      stay away from vocal-based music, because it distracts me from whats
      important. I also like Joshua Kyan Aalampour, and Shiro
      <br />
      <br />
      However, I do enjoy a bit of lyrical music from time to time such as 21
      Savage, Morgan Wallen, Yungblud, and Dominik Fike. But from a productivity
      standpoint, it is often of little utility to listen to these
      artists(besides opening up my mind to creativity), and will only typically
      listen to these when I am stressed or under the weather. I often find that
      transitioning between many of the contemporary songs brings me much higher
      mood swings, which results in my output and energy falling into an
      unpredictable manner.
    </p>
  </div>
</template>

<style scoped>
p {
  margin: 0 0 1rem 0;
  padding-bottom: 1rem;
}
li {
  padding-bottom: 1rem;
}
</style>