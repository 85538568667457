<template>
  <div>
    <h3>Readings</h3>
    <p>random reading stuff..........</p>
    <p>random reading stuff..........</p>
    <p>random reading stuff..........</p>
    <p>random reading stuff..........</p>
    <p>random reading stuff..........</p>
    <p>random reading stuff..........</p>
  </div>
</template>