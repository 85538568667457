<template>
    <div>
        <h3>intro</h3>
      <p>update Jan 2024: <a href="/music"> added music/podcast/audiobook section</a></p>
        <i>preface: this bio and intro page is an egotistical mess thats quite frankly pretty obnoxious(or at least unfun) to read.. bear with me as I refactor it(Jan 2024)</i>
      <p>
        Hey, my name is Noah Olsen, and I'm a relentlessly curious software
        engineer who is on a mission to change the world through the integration
        of business, technology, and unlike fields.
      </p>
      <p>
        Last summer, I worked at an innovative venture capital firm and was
        given the opportunity to work alongside some of the most exciting
        technologies of our future. The ability to notice a problem in the real
        world and be able to create scalable solutions through technology was
        incredibly intriguing to me. Learning to build software that billions of
        people could use was a no-brainer; I needed to be a part of this as soon
        as possible.
      </p>
      <p>
        As I was continuing my college journey, I started doing software
        engineering on the side and eventually realized that I wanted to do this
        full-time. The decision to drop out and enroll in a bootcamp was a
        no-brainer. Time is the most important resource to me, and I wanted to
        learn from the best in the world as soon as possible!
      </p>
      <p>
        In my spare time, I enjoy learning, learning, and more learning. Whether
        this is studying history's greatest entrepreneurs, exploring the
        operations of a grocery store for 3 hours, reading science fiction, or
        3d printing robots, these are the things that get me going. One of my
        favorite philosophical mannerisms is ‘don’t be a sheep,’ which I tend to
        model much of my life after.
      </p>
    </div>
</template>